import React, {Suspense} from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";

import Home from './pages/Home';
import GrupoEci from './pages/GrupoEci';
import PoliticasDePrivacidade from './pages/PoliticasDePrivacidade';

const Routes = () => (
    <Suspense fallback={<div>&nbsp;</div>}>
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/grupoeci" component={GrupoEci} />
                <Route path="/politicas-de-privacidade" component={PoliticasDePrivacidade} />
            </Switch>
        </Router>
    </Suspense>
);

export default Routes;
