import React, {useEffect} from 'react';
import Header from '../../components/Header';

import Intro from "./components/Intro";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import {WOW} from "wowjs";

function Home() {

    useEffect(() => {
        const wow = new WOW({
            live: false
        });
        wow.init();
    });

    return (
        <>
            <Header/>
            <Intro/>
            <main id="main">
                <Contact/>
            </main>
            <Footer/>
        </>
    );
}

export default Home;