import React from 'react';
import {Item, List, Wrapper} from "./styles";

import brandset from '../../../../../../assets/empresas/brandset4.png';
import flag from '../../../../../../assets/empresas/flag.png';
import intramark from '../../../../../../assets/empresas/intramark3.png';
import mindo from '../../../../../../assets/empresas/mindo3.png';
import sagrada from '../../../../../../assets/empresas/sagrada.png';
import silo from '../../../../../../assets/empresas/silo3.png';
import progiro from '../../../../../../assets/empresas/progiro3.png';

function CompanyList(props) {
    const images = {
        'brandset': brandset,
        'progiro': progiro,
        'flag': flag,
        'intramark': intramark,
        'mindo': mindo,
        'sagrada': sagrada,
        'silo': silo,
    };

    return (
        <Wrapper className="wow bounceInUp">
            <p>{props.data.descricao}</p>

            <List>
                {props.data.empresas.map((item, i) => {
                    let image = images[item.imagem];

                    return (
                        <Item key={i} className="wow bounceInUp">
                            <a href={item.link} target="_blank" rel="noopener noreferrer"
                               title={item.nome}>
                                <img src={image} alt={item.nome} title={item.nome}
                                     className="img-fluid"/>
                            </a>
                        </Item>
                    );
                })}
            </List>
        </Wrapper>
    );
}

export default CompanyList;