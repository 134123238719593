import React from 'react';
import Gallery from "react-photo-gallery";
import {FiArrowUpRight} from 'react-icons/fi';
import styled from 'styled-components';
import {isMobile} from 'react-device-detect';

import 'react-image-lightbox/style.css';

import acrediteeganhe from '../../assets/cases/acredite-e-ganhe-telhanorte.jpg';
import app_banco24horas from '../../assets/cases/app_banco24horas.jpg';
import app_titan from '../../assets/cases/app_escolha_seu_destino_titan.png';
import votorantim from '../../assets/cases/app_votorantim.jpg';
import logitech from '../../assets/cases/canal_logitech.jpg';
import titan from '../../assets/cases/escolha_seu_destino_titan.png';
import fs80 from '../../assets/cases/fs80.jpg';
import gertec from '../../assets/cases/gertec.png';
import eldorado from '../../assets/cases/intranet_eldorado.png';
import keeplight from '../../assets/cases/keeplight.jpg';
import liberty from '../../assets/cases/liberty.png';
import total from '../../assets/cases/o_grande_desafio_total.jpg';
import oraclekids from '../../assets/cases/oracle_kids.png';
import rma from '../../assets/cases/rma.jpg';
import stihlprojardim from '../../assets/cases/stihl-pro-jardim.jpg';
import vendapremiada from '../../assets/cases/venda_premiada_stihl_2.jpg';

import {portfolio} from '../../services/repositorio.js';

const Title3 = styled.h3`  
`;

const SectionWrapper = styled.section`
    background-color: #fff;
`;

function Portfolio() {
    const cases = {
        'acredite-e-ganhe-telhanorte.jpg': acrediteeganhe,
        'app_banco24horas.jpg': app_banco24horas,
        'app_escolha_seu_destino_titan.png': app_titan,
        'app_votorantim.jpg': votorantim,
        'canal_logitech.jpg': logitech,
        'escolha_seu_destino_titan.png': titan,
        'fs80.jpg': fs80,
        'gertec.png': gertec,
        'intranet_eldorado.png': eldorado,
        'keeplight.jpg': keeplight,
        'liberty.png': liberty,
        'o_grande_desafio_total.jpg': total,
        'oracle_kids.png': oraclekids,
        'rma.jpg': rma,
        'stihl-pro-jardim.jpg': stihlprojardim,
        'venda_premiada_stihl_2.jpg': vendapremiada
    };

    const imageRendererContent = (photo) => {
        return (<React.Fragment key={photo.i}>
            <img src={photo.src} width={photo.width} height={photo.height} alt={photo.nome} />
            <span className="gallery-label">
                <h3>
                    {photo.nome}
                    {photo.link && isMobile &&
                        <a href={photo.link} rel="nofollow noreferrer" title={photo.nome}>
                            <FiArrowUpRight/>
                        </a>
                    }
                    {photo.link && !isMobile && <FiArrowUpRight/>}
                </h3>
                <h5>{photo.categoria}</h5>
                <p>{photo.descricao}</p>
            </span>
        </React.Fragment>);
    };

    const imageRenderer = (
        ({ photo }) => (
            <React.Fragment key={photo.i}>
                {photo.link && !isMobile &&
                    <a className="gallery-card" href={photo.link} rel="nofollow noreferrer" title={photo.nome}>
                        {imageRendererContent(photo)}
                    </a>
                }
                {(!photo.link || isMobile) &&
                    <div className="gallery-card">
                        {imageRendererContent(photo)}
                    </div>
                }
            </React.Fragment>
        )
    );

    return (
        <>
            <SectionWrapper id="portfolio">
                <div className="container-fluid">
                    <header className="section-header">
                        <Title3 className="section-title">{portfolio.titulo}</Title3>
                    </header>
                    <div className="row portfolio-container wow fadeInUp">
                        <Gallery renderImage={imageRenderer} photos={portfolio.lista.map((item, i) => {
                            return {
                                i: i,
                                src: cases[item.imagem],
                                width: item.w,
                                height: item.h,
                                nome: item.nome,
                                descricao: item.descricao,
                                categoria: item.categoria,
                                link: item.link
                            }
                        })} />
                    </div>
                </div>
            </SectionWrapper>
        </>
    );
}

export default Portfolio;