import React, {useState, useEffect} from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import MobileNavWrapper from './components/MobileNavWrapper';
import BrowserNavWrapper from './components/BrowserNavWrapper';

import progiro from '../../assets/logobranco_2.svg';
import {menu} from "../../services/repositorio";

const Logo = styled.img`
    max-height: 40px;
`;

function Header() {
    const [isScrolled, setScrolled] = useState(window.pageYOffset > 100);

    const cbIsScrolled = () => {
        setScrolled(window.pageYOffset > 100);
    };

    const inPage = () => {
        const thisPage = menu.find((item) => item.path === window.location.pathname);
        return thisPage.page;
    };

    useEffect(() => {
        window.addEventListener('scroll', cbIsScrolled);
        return () => window.removeEventListener('scroll', cbIsScrolled);
    });

    return (
        <>
            <header id="header" className={isScrolled ? "header-scrolled" : ""}>
                <div className="container-fluid">
                    <div id="logo" className="pull-left">
                        <Link to="intro"
                              spy={true}
                              smooth={true}
                              offset={0}
                              duration={500}
                              style={{cursor: 'pointer'}}>
                            <Logo src={progiro} alt="Logo Progiro" title="Progiro" />
                        </Link>
                    </div>
                    <MobileNavWrapper menu={menu} inPage={inPage()}/>
                    <BrowserNavWrapper menu={menu} inPage={inPage()}/>
                </div>
            </header>
        </>
    );
}

export default Header;