import React, {useState} from 'react';
import {useFormik, FormikProvider, Form} from "formik";
import {VscLoading} from 'react-icons/vsc';
import styled, {keyframes} from "styled-components";
import * as Yup from "yup";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import contactFormSubmit from '../../services/contactForm';

import {contato, solucoes} from '../../services/repositorio.js';
import {Modal} from "react-bootstrap";

const form = {
    width: '70%',
    margin: 'auto'
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const Rotate = styled.div`
    display: inline-block;
    text-align: center;
    animation: ${rotate} 2s linear infinite;
    line-height: 0;
    margin: 0;
    padding: 0;
    font-size: 3rem;   
    left: 130px;
    position: relative;
`;

function ContactOnExit({show, setShow}) {
    const [isSent, setSent] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: ""
        },
        onSubmit: async (values) => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setSent(true);
            }, 1500);
            setTimeout(() => {
                setSent(false);
                setShow(false);
            }, 10000);
            contactFormSubmit({...values, subject: 'Formulário de saída do site Progiro'});
            formik.resetForm({});
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(4, "Seu nome precisa ter pelo menos 4 caracteres")
                .max(120, "Seu nome precisa ter menos de 120 caracteres")
                .required("Por favor, insira seu nome"),
            email: Yup.string().email('Por favor, insira um e-mail válido').required('Por favor, insira um e-mail para contato'),
        })
    });

    return (
        <Modal show={show} onHide={() => setShow(false)} animation={false}>
            <Modal.Header closeButton className="section-header exit-intent">
                <h3 dangerouslySetInnerHTML={{__html: contato.exitIntent.titulo}}/>
            </Modal.Header>
            <Modal.Body>
                <section id="contact" className="section-bg mt-3 mb-5" style={{padding: 0, background: 'none'}}>
                    <div className="container">
                        <div dangerouslySetInnerHTML={{__html: contato.exitIntent.descricao}}/>

                        <div className="form" style={form}>
                            {isLoading &&
                                <Rotate><VscLoading/></Rotate>
                            }
                            {isSent &&
                                <div id="sendmessage"
                                     className={isSent ? 'show' : ''}
                                     dangerouslySetInnerHTML={{__html: contato.exitIntent.sucesso}}/>
                            }
                            {!isSent && !isLoading &&
                                <FormikProvider value={formik}>
                                    <Form>
                                        <TextInput label="Nome" id="name" name="name" type="text" placeholder="Nome"/>
                                        <TextInput label="E-mail" id="email" name="email" type="email"
                                                   placeholder="E-mail"/>
                                        <SelectInput
                                            label="Todas as soluções"
                                            id="message"
                                            name="message"
                                            placeholder="Selecione uma solução"
                                            options={solucoes.lista.map((item) => {
                                                return {value: item.titulo, label: item.titulo};
                                            })}
                                        />
                                        <div className="text-center">
                                            <button type="submit" disabled={isSent}>Enviar</button>
                                        </div>
                                    </Form>
                                </FormikProvider>
                            }
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
}

export default ContactOnExit;