import axios from 'axios';

export default function submit(data) {

    const url = process.env.REACT_APP_CONTACT_FORM_URL;

    axios.post(url, {
        ...data,
        source: 'contato@progiro.com.br'
    });
}