import React from 'react';
import { FiMapPin, FiPhone } from 'react-icons/fi';
import { GoMail } from 'react-icons/go';

function ContactInfo(props) {
    const contato = props.data;

    let phone = contato.telefone.valor;
    phone = phone.replace(/[^0-9+]+/g, '');

    return (
        <>
            <div className="row contact-info wow fadeInUp">
                <div className="col-md-4">
                    <div className="contact-address">
                        <FiMapPin/>
                        <h3>{contato.endereco.label}</h3>
                        <address>
                            {contato.endereco.linha1}<br/>
                            {contato.endereco.linha2}<br/>
                            {contato.endereco.linha3}
                        </address>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="contact-phone">
                        <FiPhone/>
                        <h3>{contato.telefone.label}</h3>
                        <p><a href={"tel:" + phone}>{contato.telefone.valor}</a></p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="contact-email">
                        <GoMail/>
                        <h3>{contato.email.label}</h3>
                        <p><a href={"mailto:" + contato.email.valor}>{contato.email.valor}</a></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactInfo;