import React from 'react';
import {Wrapper} from './styles.js';

import { politicas as data } from '../../../../services/repositorio.js';

function Intro() {
    return (
        <section id="intro2">
            <Wrapper className="container-fluid">
                <div className="container">
                    <header className="section-header">
                        <h3>{data.titulo}</h3>
                    </header>

                    <embed src={'https://drive.google.com/viewerng/viewer?embedded=true&url=' + data.arquivo}
                           width="100%" height="550px"/>
                    <p><small>Caso não esteja visualizando a nossa Política de Privacidade, <a href={data.arquivo} download rel="nofollow noreferrer">clique aqui</a>.</small></p>
                </div>
            </Wrapper>
        </section>
    );
}

export default Intro;