import React, {useEffect,useState} from 'react';
import Routes from './routes';
import exitIntent from 'exit-intent';

import './styles/global.css';
import ContactOnExit from "./components/ContactOnExit";

const App = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        // Initialise
        const removeExitIntent = exitIntent({
            threshold: 1,
            maxDisplays: 1,
            eventThrottle: 50,
            onExitIntent: () => {
                setShow(true);
            }
        });

        return () => removeExitIntent();
    }, []);

    return (
        <>
            <Routes/>
            <ContactOnExit show={show} setShow={setShow}/>
        </>
    );
};
export default App;
