import React from 'react';
import {useField, Field} from 'formik';
import styled from 'styled-components';

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Feedback = styled.div`
    color: red;
`;

function SelectInput({ label, helpText, options, ...props}) {
    const [field, meta] = useField(props);
    const [didFocus, setDidFocus] = React.useState(false);
    const handleFocus = () => setDidFocus(true);
    const showFeedback = (!!didFocus && field.value.trim().length > 2) || meta.touched;

    return (
        <div className="form-group">
            <Field as="select"
                   {...props}
                   {...field}
                   className="form-control"
                   aria-describedby={`${props.id}-feedback ${props.id}-help`}
                   onFocus={handleFocus}
                   style={{borderColor: showFeedback ? (meta.error ? "red" : "#6FB199") : "" }}>
                <option value="">{label}</option>
                {options.map((item, i) => {
                    return <option value={item.value} key={i}>{item.label}</option>
                })}
            </Field>
            <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
                {helpText}
            </div>
            <LabelWrapper>
                {" "}
                {showFeedback && meta.error ? (
                    <Feedback id={`${props.id}-feedback`} aria-live="polite">
                        <small>{meta.error}</small>
                    </Feedback>
                ) : null}
            </LabelWrapper>
        </div>
    );
}

export default SelectInput;

