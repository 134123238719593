import React from 'react';
import {FaNetworkWired} from 'react-icons/fa';
import {GiPaintBucket,GiTeamIdea} from 'react-icons/gi';
import {HiOutlineShoppingBag} from 'react-icons/hi';
import {GoMention} from 'react-icons/go';
import {ImHappy} from 'react-icons/im';
import {BsGraphUp} from 'react-icons/bs';
import {IoIosPhonePortrait} from 'react-icons/io';
import {AiFillApi} from 'react-icons/ai';

import { solucoes } from '../../services/repositorio.js';

const Solutions = React.forwardRef((props, ref) => {
    const icons = {
        'FaNetworkWired': <FaNetworkWired/>,
        'GiPaintBucket': <GiPaintBucket/>,
        'GiTeamIdea': <GiTeamIdea/>,
        'HiOutlineShoppingBag': <HiOutlineShoppingBag/>,
        'GoMention': <GoMention/>,
        'ImHappy': <ImHappy/>,
        'BsGraphUp': <BsGraphUp/>,
        'IoIosPhonePortrait': <IoIosPhonePortrait/>,
        'AiFillApi': <AiFillApi/>
    };

    return (
        <>
            <section id="solutions" ref={ref}>
                <div className="container-fluid">

                    <header className="section-header">
                        <h3>{solucoes.titulo}</h3>
                        <p>{solucoes.subtitulo}</p>
                    </header>

                    <div className="row wow fadeInUp">
                        {solucoes.lista.map((item, i) => {
                            let icon = icons[item.icone];

                            return (
                                <div className="col-lg-4 col-md-6 box mb-5" key={i}>
                                    <div className="ml-5 mr-5">
                                        <div className="icon">
                                            {icon}
                                        </div>
                                        <h4 className="title">
                                            {item.titulo}
                                        </h4>
                                        <p className="description">{item.descricao}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
});

export default Solutions;