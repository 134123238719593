import React from 'react';
import styled from 'styled-components';

import { servicos } from '../../services/repositorio.js';

import icon1 from '../../assets/services/icones progiro-01.png';
import icon2 from '../../assets/services/icones progiro-02.png';
import icon3 from '../../assets/services/icones progiro-03.png';
import icon4 from '../../assets/services/icones progiro-04.png';

const Icon = styled.img`
    max-height: 100px
`;

function FeatureServices() {
    const icons = {
        'icon1' : icon1,
        'icon2' : icon2,
        'icon3' : icon3,
        'icon4' : icon4,
    };
    return (
        <>
            <section id="featured-services">
                <div className="container">
                    <div className="row wow fadeInUp" data-wow-offset="10">
                        {servicos.lista.map((item, i) => {
                            let icon = icons[item.icone];

                            return (
                                <div className="col-lg-3 box" key={i}>
                                    <Icon src={icon} alt=""/>
                                    <h4 className="title">
                                        {item.descricao}
                                    </h4>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}

export default FeatureServices;