import styled from "styled-components";
import {isMobile} from "react-device-detect";

export const Wrapper = styled.div`
    letter-spacing: 0.8px; 
    line-height: 2; 
    //width: ${isMobile ? '100%' : '600px'};
    margin-top: 10vh;
`;

export const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-top: ${isMobile ? '0' : '10vh'};
    padding-bottom: 50px;: ${isMobile ? '50px' : '0'};
    justify-content: center;
`;

export const Item = styled.li`
    max-width: ${isMobile ? '150px' : '180px'};
    max-height: 100px;
`;