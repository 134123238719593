import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import eldorado from '../../assets/clients/eldorado.jpg';
import gertec from '../../assets/clients/gertec.jpg';
import keeplight from '../../assets/clients/keeplight.jpg';
import liberty from '../../assets/clients/liberty.jpg';
import logitech from '../../assets/clients/logitech.jpg';
import oracle from '../../assets/clients/oracle.jpg';
import stihl from '../../assets/clients/stihl.jpg';
import telhanorte from '../../assets/clients/telhanorte.jpg';
import total from '../../assets/clients/total.jpg';
import votorantim from '../../assets/clients/votorantim.jpg';

import { clientes } from '../../services/repositorio.js';


function Clients() {
    const logos = {
        'eldorado.jpg': eldorado,
        'gertec.jpg': gertec,
        'keeplight.jpg': keeplight,
        'liberty.jpg': liberty,
        'logitech.jpg': logitech,
        'oracle.jpg': oracle,
        'stihl.jpg': stihl,
        'telhanorte.jpg': telhanorte,
        'total.jpg': total,
        'votorantim.jpg': votorantim,
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
        },
        desktopSmall: {
            breakpoint: { max: 1280, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
        },
        tabletSmall: {
            breakpoint: { max: 860, min: 464 },
            items: 4
        },
        mobile: {
            breakpoint: { max: 640, min: 0 },
            items: 3
        }
    };

    return (
        <>
            <section id="clients">
                <div className="container-fluid">
                    <div className="wow fadeInUp">
                        <Carousel
                            showDots={false}
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            arrows={false}
                        >
                            {clientes.lista.map((item, i) => {
                                let logo = logos[item.logo];
                                return (
                                    <div className="ml-4 mr-4" key={i}>
                                        <a href={item.link} target="_blank" title={item.nome}
                                           rel="nofollow noopener noreferrer" draggable={false} >
                                            <img src={logo} alt={"Logo " + item.nome} className="img-fluid" draggable={false} />
                                        </a>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Clients;