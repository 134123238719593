import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Feedback = styled.div`
    color: red;
`;

const Input = styled.input`
    border-color: ${props => props.inputBorder}!important
`;

function TextInput({ label, helpText, ...props}) {
    const [field, meta] = useField(props);

    // Show inline feedback if EITHER
    // - the input is focused AND value is longer than 2 characters
    // - or, the has been visited (touched === true)
    const [didFocus, setDidFocus] = React.useState(false);
    const handleFocus = () => setDidFocus(true);
    const showFeedback =
        (!!didFocus && field.value.trim().length > 2) || meta.touched;

    return (
        <div className="form-group">
            <Input className="form-control" inputBorder={showFeedback ? (meta.error ? "red" : "#6FB199") : "" }
                as={props.type === 'textarea' ? 'textarea' : 'input'}
                {...props}
                {...field}
                aria-describedby={`${props.id}-feedback ${props.id}-help`}
                onFocus={handleFocus}
            />
            <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
                {helpText}
            </div>
            <LabelWrapper>
                {" "}
                {showFeedback && meta.error ? (
                    <Feedback id={`${props.id}-feedback`} aria-live="polite">
                        <small>{meta.error}</small>
                    </Feedback>
                ) : null}
            </LabelWrapper>
        </div>
    );
}

export default TextInput;

