import React from 'react';
import {FaUniversity} from 'react-icons/fa';
import {SiBuymeacoffee,SiGooglecalendar} from 'react-icons/si';
import {VscChecklist} from 'react-icons/vsc';

import { resultados } from '../../services/repositorio.js';
import Portfolio from "../Portfolio";

function Results() {
    const icons = {
        'FaUniversity': <FaUniversity/>,
        'SiBuymeacoffee': <SiBuymeacoffee/>,
        'SiGooglecalendar': <SiGooglecalendar/>,
        'VscChecklist': <VscChecklist/>
    };

    return (
        <>
            <section id="results" className="wow fadeIn">
                <div className="container-fluid">

                    <header className="section-header">
                        <h3>{resultados.titulo}</h3>
                    </header>

                    <div className="row wow fadeInUp">
                        {resultados.lista.map((item, i) => {
                            let icon = icons[item.icone];
                            return (
                                <div className="col-lg-3 box mt-5 mb-5" key={i}>
                                    <div className="ml-5 mr-5">
                                        {icon}
                                        <h4 className="title">
                                            {item.descricao}
                                        </h4>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <Portfolio/>
            </section>
        </>
    );
}

export default Results;