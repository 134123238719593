import React,{useState} from 'react';
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import ContactInfo from "./components/ContactInfo";
import TextInput from "../TextInput";
import contactFormSubmit from '../../services/contactForm';

import { contato } from '../../services/repositorio.js';

const form = {
    width: '70%',
    margin: 'auto'
};

function Contact() {
    const [isSent, setSent] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: ""
        },
        onSubmit: async (values) => {
            setSent(true);
            setTimeout(() => {
                setSent(false)
            }, 15000);
            contactFormSubmit({...values, subject: 'Formulário de contato do Site Progiro'});
            formik.resetForm({});
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(4, "Seu nome precisa ter pelo menos 4 caracteres")
                .max(120, "Seu nome precisa ter menos de 120 caracteres")
                .required("Por favor, insira seu nome"),
            email: Yup.string().email('Por favor, insira um e-mail válido').required('Por favor, insira um e-mail para contato'),
            message: Yup.string()
                .min(4, "Sua mensagem precisa ter pelo menos 4 caracteres")
                .max(1000, "Sua mensagem precisa ter menos de 1000 caracteres")
                .required("Por favor, insira sua mensagem")
        })
    });

    return (
        <>
            <section id="contact" className="section-bg">
                <div className="container">

                    <div className="section-header">
                        <h3>{contato.titulo}</h3>
                    </div>

                    <ContactInfo data={contato}/>

                    <div className="form" style={form}>
                        <div id="sendmessage" className={isSent ? 'show' : ''}>{contato.sucesso}</div>
                        <FormikProvider value={formik}>
                            <Form>
                                <TextInput label="Nome" id="name" name="name" type="text" placeholder="Nome" />
                                <TextInput label="E-mail" id="email" name="email" type="email" placeholder="E-mail" />
                                <TextInput label="Mensagem" id="message" name="message" type="textarea" placeholder="Mensagem" />
                                <div className="text-center">
                                    <button type="submit" disabled={isSent}>Enviar</button>
                                </div>
                            </Form>
                        </FormikProvider>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Contact;