import React from 'react';
import {BrowserView} from 'react-device-detect';
import NavItem from '../NavItem';

function BrowserNavWrapper({menu,inPage}) {

    return (
        <>
            <BrowserView>
                <nav id="nav-menu-container">
                    <ul className="nav-menu">
                        {menu.map((item, i) => (
                            <NavItem item={item} setOpen={() => {}} key={i} inPage={inPage}/>
                        ))}
                    </ul>
                </nav>
            </BrowserView>
        </>
    );
}

export default BrowserNavWrapper;