import styled from "styled-components";

export default styled.div`  /* The image used */
    background-image: url(${props => props.img});
    
    /* Set a specific height */
    height: 350px;
    
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;