import React from 'react';
import styled from 'styled-components';
import Parallax from '../Parallax';

import bgParallax from '../../assets/parallax_grupoeci.jpg';

import { sobre } from '../../services/repositorio.js';
import Clients from "../Clients";

const Content = styled.div`
    width: 80%; 
    letter-spacing: 0.8px; 
    line-height: 2;
`;

function About() {

    return (
        <>
            <section id="about">
                <div className="container">
                    <header className="section-header">
                        <h3>{sobre.titulo}</h3>
                    </header>
                    <div className="row wow bounceInUp" data-wow-duration="1.4s" data-wow-offset="50">
                        <div className="col-lg-12 col-md-12 box mt-5 mb-5">
                            <Content className="container" id="mobile-about"
                                     dangerouslySetInnerHTML={{ __html: sobre.descricao }} />
                        </div>
                    </div>
                </div>
            </section>

            <Clients/>
            <Parallax img={bgParallax}/>
        </>
    );
}

export default About;