import React from 'react';
import { Link } from 'react-scroll';

import {home} from '../../services/repositorio.js';

function Intro() {

    return (
        <>
            <section id="intro">
                <div className="intro-container">
                    <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">
                        <div className="carousel-inner" role="listbox">
                            <div className="carousel-item carousel-1 active">
                                <div className="carousel-background">
                                </div>
                                <div className="carousel-container">
                                    <div className="carousel-content">
                                        <h2 dangerouslySetInnerHTML={{ __html: home.titulo }} />
                                        <p>{home.description}</p>

                                        <Link className="btn-get-started"
                                              to={home.action.hash}
                                              spy={true}
                                              smooth={true}
                                              offset={-50}
                                              duration={500}
                                              style={{cursor: 'pointer'}}>
                                            {home.action.descricao}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Intro;