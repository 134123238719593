import React, {useState} from 'react';
import {MobileView} from 'react-device-detect';
import HamburgerMenu from 'react-hamburger-menu';
import Sidebar from "react-sidebar";

import NavItem from '../NavItem';
import progiro from "../../../../assets/logobranco_2.svg";

import {sidebarStyled,Logo,Wrapper} from './styles.js';

function MobileNavWrapper({menu,inPage}) {
    const [isOpen, setOpen] = useState(false);

    const SideBarContent = (
        <Wrapper>
            <Logo src={progiro} alt="Logo Progiro" title="Progiro" />
            <hr/>
            <div>
                <ul className="nav flex-column nav-menu">
                    {menu.map((item, i) => (
                        <NavItem item={item} setOpen={setOpen} key={i} inPage={inPage}/>
                    ))}
                </ul>
            </div>
        </Wrapper>
    );

    return (
        <>
            <MobileView>
                <Sidebar
                    sidebar={SideBarContent}
                    open={isOpen}
                    onSetOpen={setOpen}
                    styles={sidebarStyled}
                    inPage={inPage}>
                    <></>
                </Sidebar>
                <nav id="nav-menu-container">
                    <HamburgerMenu
                        isOpen={isOpen}
                        menuClicked={() => setOpen(!isOpen)}
                        width={35}
                        height={30}
                        strokeWidth={3}
                        rotate={0}
                        color='white'
                        borderRadius={0}
                        animationDuration={0.5}
                    />
                </nav>
            </MobileView>
        </>
    );
}

export default MobileNavWrapper;