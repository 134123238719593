import React, {useEffect, useState} from 'react';
import { Link } from 'react-scroll';
import {FiChevronUp} from 'react-icons/fi';
import styled from 'styled-components';

import progiro from '../../assets/logobranco_2.svg';

import { footer } from '../../services/repositorio.js';
import {Link as RouterLink} from "react-router-dom";

const Logo = styled.img`
    max-height: 40px
`;

const ContactWrapper = styled.div`
    float: right;
    text-align: right;
`;

const A = styled.a`
    color: white;
`;

function Footer() {
    const [isScrolled, setScrolled] = useState(window.pageYOffset > 100);
    const contact = footer.contato;
    let phone = contact.telefone.valor;
    phone = phone.replace(/[^0-9+]+/g, '');

    const cbIsScrolled = () => {
        setScrolled(window.pageYOffset > 500);
    };

    useEffect(() => {
        window.addEventListener('scroll', cbIsScrolled);
        return () => window.removeEventListener('scroll', cbIsScrolled);
    });

    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 footer-info">
                                <Link to="intro"
                                      spy={true}
                                      smooth={true}
                                      offset={0}
                                      duration={500}
                                      style={{cursor: 'pointer'}}>
                                    <Logo className="mb-3" src={progiro} alt="Logo Progiro" title="Progiro"/>
                                </Link>
                                <h5>{footer.titulo}</h5>
                                <p>
                                    {footer.descricao}
                                </p>
                            </div>

                            <ContactWrapper className="col-lg-8 col-md-6 footer-contact">
                                <h4>{contact.endereco.label}</h4>
                                <p>
                                    {contact.endereco.linha1}<br/>
                                    {contact.endereco.linha2}<br/>
                                    {contact.endereco.linha3}<br/>
                                    <strong>{contact.telefone.label}:&nbsp;</strong>
                                    <A href={"tel:" + phone}>{contact.telefone.valor}</A><br/>
                                    <strong>{contact.email.label}:&nbsp;</strong>
                                    <A href={"mailto:" + contact.email.valor}>{contact.email.valor}</A>
                                </p>
                            </ContactWrapper>
                        </div>
                    </div>
                </div>

                <div className="container text-center">
                    <span className="copyright" dangerouslySetInnerHTML={{ __html: footer.copyright }}/>{' '}
                    |{' '}
                    <RouterLink to="politicas-de-privacidade">
                        Políticas de Privacidade
                    </RouterLink>
                </div>
            </footer>

            <Link className={"back-to-top " + (isScrolled ? "fadeIn" : "")}
                  to="intro"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  style={{cursor: 'pointer'}}>
                <FiChevronUp/>
            </Link>
        </>
    );
}

export default Footer;