import React, {useEffect} from 'react';
import Header from '../../components/Header';

import Intro from "./components/Intro";
import Footer from "../../components/Footer";
import {WOW} from "wowjs";

function PoliticasDePrivacidade() {

    useEffect(() => {
        const wow = new WOW({
            live: false
        });
        wow.init();
    });

    return (
        <>
            <Header/>
            <Intro/>
            <Footer/>
        </>
    );
}

export default PoliticasDePrivacidade;