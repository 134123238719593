import React from 'react';
import CompanyList from './components/CompanyList';
import Parallax from '../../../../components/Parallax';

import bgGrupoEci from "../../../../assets/grupoeci.jpg";

import {Wrapper} from './styles.js';

import grupoeci from "../../../../assets/empresas/grupoeci.png";

import { grupoeci as data } from '../../../../services/repositorio.js';

function Intro() {

    return (
        <section id="intro2">
            <Wrapper className="container-fluid">
                <header className="section-header text-center">
                    <img src={grupoeci} alt="Logo Grupo ECI" title="Grupo ECI"
                             className="img-fluid"/>
                </header>
                <div className="container">
                    <CompanyList data={data}/>
                </div>
            </Wrapper>
            <Parallax img={bgGrupoEci}/>
        </section>
    );
}

export default Intro;