import React, {useEffect} from 'react';
import {Link as RouterLink, useHistory} from "react-router-dom";
import {Link as ScrollLink, scroller, scrollSpy} from 'react-scroll';

function NavItem({item,setOpen,inPage}) {
    const history = useHistory();
    const isPage = item.page;
    const thisPage = window.location.pathname === item.path;
    const shouldBeRouted = isPage || inPage;
    const shouldBeScrolled = !inPage && !isPage;

    useEffect(() => {
        let isRedirect = history.action === 'PUSH';
        if (isRedirect) {
            scroller.scrollTo(history.location.hash.substring(1) || 'intro', {
                duration: 1500,
                offset: -50,
                delay: 500,
                isDynamic: true,
                smooth: true,
            })
        }
        scrollSpy.update();
    }, [history]);

    if (!item.header) {
        return <></>;
    }

    return (
        <li className="nav-item">
            {shouldBeRouted &&
                <RouterLink to={{pathname: item.path, hash: '#' + item.hash }}
                            className={inPage && thisPage ? 'active' : ''}
                            onClick={(e) => {
                                setOpen(false);
                            }}>
                    {item.menu}
                </RouterLink>
            }

            {shouldBeScrolled &&
                <ScrollLink
                    activeClass='active'
                    to={item.hash}
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                    isDynamic={true}
                    style={{cursor: 'pointer'}}
                    onClick={() => setOpen(false)}>
                    {item.menu}
                </ScrollLink>
            }
        </li>
    );
}

export default NavItem;