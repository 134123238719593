export const home = {
    "titulo": "Soluções digitais<br />para sua empresa",
    "subtitulo": "Desenvolvemos projetos personalizados visando engajar, motivar, informar, controlar e aumentar a performance dos usuários através de plataformas inteligentes de comunicação, vendas e monitoramento.",
    "action": {
        "descricao": "Conheça nossas soluções",
        "path": "/",
        "hash": "solutions"
    }
};

export const menu = [
    {
        "menu": "HOME",
        "path": "/",
        "hash": "intro",
        "header": true,
        "page": false
    },
    {
        "menu": "SOBRE",
        "path": "/",
        "hash": "about",
        "header": true,
        "page": false
    },
    {
        "menu": "SOLUÇÕES",
        "path": "/",
        "hash": "solutions",
        "header": true,
        "page": false
    },
    {
        "menu": "CASES",
        "path": "/",
        "hash": "portfolio",
        "header": true,
        "page": false
    },
    {
        "menu": "CONTATO",
        "path": "/",
        "hash": "contact",
        "header": true,
        "page": false
    },
    {
        "menu": "GRUPO ECI",
        "path": "/grupoeci",
        "hash": "",
        "header": true,
        "page": true
    },
    {
        "menu": "POLÍTICAS DE PRIVACIDADE",
        "path": "/politicas-de-privacidade",
        "hash": "",
        "header": false,
        "page": true
    }
];

export const sobre = {
    "titulo": "SOBRE",
    "descricao": "<p>Com 25 anos de experiência no mercado de soluções digitais, possuímos expertise em desenvolvimento de soluções em tecnologia, comunicação, vendas e monitoramento.</p>" +
        "<p>Com equipes próprias de planejamento, criação, programação, BI e suporte, conseguimos criar soluções práticas e inovadoras para que nossos clientes consigam automatizar processos internos com mais controle, tempo e, principalmente, que possam atingir seus objetivos com alta performance e produtos Progiro.</p>"
};

export const servicos = {
    "lista": [
        {
            "icone": "icon1",
            "descricao": "Entendimento das necessidades e definição dos objetivos do projeto."
        },
        {
            "icone": "icon2",
            "descricao": "Planejamento e consultoria completa."
        },
        {
            "icone": "icon3",
            "descricao": "Soluções em tecnologia, comunicação, vendas e monitoramento."
        },
        {
            "icone": "icon4",
            "descricao": "Alta performance com produtos Progiro."
        }
    ]
};

export const solucoes = {
    "titulo": "SOLUÇÕES",
    "subtitulo": "Conheça nossas principais soluções digitais, versáteis e personalizadas:",
    "lista": [
        {
            "titulo": "Intranets sob medida",
            "descricao": "Agilidade, eficiência e engajamento em processos e comunicação com os funcionários da sua empresa.",
            "icone": "FaNetworkWired"
        },
        {
            "titulo": "Plataformas de personalização de peças",
            "descricao": "Padronizações, facilidade e rapidez na personalização de peças publicitárias de forma dinâmica e intuitiva.",
            "icone": "GiPaintBucket"
        },
        {
            "titulo": "Sites e e-commerces",
            "descricao": "Modernos e com excelente impacto visual, possuem facilidade de gerenciamento, além de um suporte técnico ágil e qualificado.",
            "icone": "HiOutlineShoppingBag"
        },
        {
            "titulo": "Hotsites promocionais",
            "descricao": "Simples, claros e autoexplicativos, para campanhas pontuais que exijam ou não aprovação junto aos órgãos regulatórios.",
            "icone": "GoMention"
        },
        {
            "titulo": "Programas de incentivo e fidelidade",
            "descricao": "Benefícios e vantagens para seus funcionários, com excelentes resultados motivacionais e de vendas para seu negócio.",
            "icone": "ImHappy"
        },
        {
            "titulo": "Dashboards e relatórios",
            "descricao": "Facilitando o acompanhamento e medição de resultados, disponibilizamos relatórios segmentados e dashboards intuitivas.",
            "icone": "BsGraphUp"
        },
        {
            "titulo": "Aplicativos",
            "descricao": "Dinâmicos e sob medida. Desenvolvemos uma experiência de navegação personalizada na palma da sua mão.",
            "icone": "IoIosPhonePortrait"
        },
        {
            "titulo": "Integrações entre sistemas",
            "descricao": "Otimizações e automatizações de processos para melhor performance e resultados.",
            "icone": "AiFillApi"
        },
        {
            "titulo": "Sistemas sob medida",
            "descricao": "Únicos e versáteis, entendemos suas necessidades e desenvolvemos sistemas 100% personalizados.",
            "icone": "GiTeamIdea"
        }
    ]
};

export const resultados = {
    "titulo": "ENTREGAMOS RESULTADOS",
    "subtitulo": "Conheça nossas principais soluções digitais, versáteis e personalizadas:",
    "slider": [
        "imagem1",
        "imagem2",
        "imagem3",
        "imagem4"
    ],
    "lista": [
        {
            "icone": "FaUniversity",
            "descricao": "Equipe de desenvolvedores certificados com níveis pleno e sênior"
        },
        {
            "icone": "SiBuymeacoffee",
            "descricao": "Expertise em: PHP, .Net, Javascript, HTML5, Mobile, ReactJS/Native, DevOps, AWS, Relatórios e Dashboards"
        },
        {
            "icone": "VscChecklist",
            "descricao": "As telas dos sistemas são validadas por um profissional de UX, garantindo melhor usabilidade"
        },
        {
            "icone": "SiGooglecalendar",
            "descricao": "100% dos projetos entregues dentro do cronograma alinhado entre contratada e contratante"
        }
    ]
};

export const portfolio = {
    "titulo": "CASES",
    "categorias": [
        {
            "id": "*",
            "titulo": "Todos"
        },
        {
            "id": "programas-de-incentivo",
            "titulo": "Programas de Incentivo"
        },
        {
            "id": "sistemas-sob-medida",
            "titulo": "Sistemas sob medida"
        },
        {
            "id": "websites-e-ecommerce",
            "titulo": "Websites e E-commerces"
        },
        {
            "id": "sites-promocionais",
            "titulo": "Sites Promocionais"
        },
        {
            "id": "aplicativos",
            "titulo": "Aplicativos"
        }
    ],
    "lista": [
        {
            "id": 1,
            "categoria_id": "sistemas-sob-medida",
            "nome": "Intranet Eldorado",
            "descricao": "Plataforma de conexão e comunicação que permiti que a Eldorado se comunique de forma rápida e eficiente com seus funcionários",
            "categoria": "Sistemas sob medida",
            "imagem": "intranet_eldorado.png",
            "link": null,
            "w": 2,
            "h": 1
        },
        {
            "id": 4,
            "categoria_id": "programas-de-incentivo",
            "nome": "Acredite e Ganhe",
            "descricao": "Plataforma de programa de incentivo com foco em incentivar e bonificar os vendedores mais engajados da rede Telhanorte / Tumelero",
            "categoria": "Programas de Incentivo",
            "imagem": "acredite-e-ganhe-telhanorte.jpg",
            "link": null,
            "w": 2,
            "h": 1
        },
        {
            "id": 5,
            "categoria_id": "programas-de-incentivo",
            "nome": "Venda Premiada STIHL",
            "descricao": "Plataforma de programa de incentivo com foco motivar e premiar os revendedores e concessionários da STIHL",
            "categoria": "Programas de Incentivo",
            "imagem": "venda_premiada_stihl_2.jpg",
            "link": null,
            "w": 2,
            "h": 1
        },
        {
            "id": 6,
            "categoria_id": "sistemas-sob-medida",
            "nome": "Liberty",
            "descricao": "Plataforma de personalização de peças que permiti que os responsáveis por negociais os serviços da Liberty consigam criar peças de comunicação com rapidez e praticidade",
            "categoria": "Sistemas sob medida",
            "imagem": "liberty.png",
            "link": null,
            "w": 2,
            "h": 1
        },
        {
            "id": 7,
            "categoria_id": "websites-e-ecommerce",
            "nome": "Gertec",
            "descricao": "Plataforma de e-commerce feito sob medida para a Gertec e seus parceiros comercializarem produtos Gertec",
            "categoria": "Websites e E-commerce",
            "imagem": "gertec.png",
            "link": "https://www.gertec.com.br",
            "w": 2,
            "h": 1
        },
        {
            "id": 8,
            "categoria_id": "sites-promocionais",
            "nome": "PRÓ-JARDIM STIHL",
            "descricao": "Plataforma promocional para revendedores e concessionários da STIHL",
            "categoria": "Sites Promocionais",
            "imagem": "stihl-pro-jardim.jpg",
            "link": null,
            "w": 2,
            "h": 1
        }
    ]
};

export const clientes = {
    "titulo": "CONHEÇA ALGUNS DE NOSSOS CLIENTES",
    "lista": [
        {
            "nome": "Total Brasil",
            "logo": "total.jpg",
            "link": "https://www.totalbrasil.com/"
        },
        {
            "nome": "Oracle",
            "logo": "oracle.jpg",
            "link": "https://www.oracle.com/br/index.html"
        },
        {
            "nome": "Stihl",
            "logo": "stihl.jpg",
            "link": "https://www.stihl.com.br/"
        },
        {
            "nome": "Gertec",
            "logo": "gertec.jpg",
            "link": "https://www.gertec.com.br/"
        },
        {
            "nome": "Liberty",
            "logo": "liberty.jpg",
            "link": "https://www.libertyseguros.com.br/Pages/Home.aspx"
        },
        {
            "nome": "Eldorado",
            "logo": "eldorado.jpg",
            "link": "http://www.eldoradobrasil.com.br/"
        },
        {
            "nome": "Telhanorte",
            "logo": "telhanorte.jpg",
            "link": "https://www.telhanorte.com.br/"
        },
        {
            "nome": "Votorantim",
            "logo": "votorantim.jpg",
            "link": "https://www.votorantim.com.br/"
        }
    ]
};

export const grupoeci = {
    "titulo": "GRUPO ECI",
    "descricao": "O Grupo ECI é um dos maiores grupos independentes de comunicação do Brasil. São 6 empresas especialistas e complementares que trabalham de forma autônoma, mas que se unem quando necessário, fornecendo todas as soluções que sua empresa precisa em um único lugar.",
    "empresas": [
        {
            "nome": "Flag Comunicação",
            "imagem": "flag",
            "link": "http://www.flagcomunicacao.com.br/"
        },
        {
            "nome": "Progiro",
            "imagem": "progiro",
            "link": "http://www.progiro.com.br/"
        },
        {
            "nome": "Mindo",
            "imagem": "mindo",
            "link": "http://mindo.com.br/"
        },
        {
            "nome": "Silo",
            "imagem": "silo",
            "link": "http://www.siloagencia.com.br/"
        },
        {
            "nome": "Intramark",
            "imagem": "intramark",
            "link": "http://www.intramark.com.br/"
        },
        {
            "nome": "Sagrada Comunicação",
            "imagem": "sagrada",
            "link": "https://www.sagradacomunicacao.com.br/"
        }
    ]
};

export const contato = {
    "titulo": "CONTATO",
    "sucesso": "Mensagem enviada, entraremos em contato em breve.",
    "exitIntent": {
        "titulo": "Obrigado pela sua visita!",
        "descricao": "<p>Caso tenha interesse em alguma das nossas soluções ou queira ficar por dentro das novidades, preencha abaixo o formulário com seu nome e e-mail que entraremos em contato.</p>",
        "sucesso": "Obrigado! Sua mensagem foi enviada com sucesso."
    },
    "endereco": {
        "label": "Endereço",
        "linha1": "Alameda Jaú, 1303",
        "linha2": "Jardim Paulista - São Paulo - SP",
        "linha3": "01420-005 - Brasil"
    },
    "telefone": {
        "label": "Telefone",
        "valor": "+55 11 3047-4060"
    },
    "email": {
        "label": "E-mail",
        "valor": "contato@progiro.com.br"
    }
};

export const politicas = {
    "titulo": "Políticas de Privacidade",
    "arquivo": "https://cdn.progiro.com.br/docs/politicas-de-privacidade-lgpd_20201113.pdf"
};

export const footer = {
    "titulo": "Soluções digitais para sua empresa",
    "descricao": "Desenvolvemos projetos personalizados visando engajar, motivar, informar, controlar e aumentar a performance dos usuários através de plataformas inteligentes de comunicação, vendas e monitoramento.",
    "copyright": "2023 &copy; Copyright <strong>Progiro</strong>. Todos os direitos reservados.",
    "contato": contato
};